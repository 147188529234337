import { OutputDiscountType } from './couponSteps.types';
import { IEnforcementUser } from './user.types';

export interface IDealsList {
  deals: Array<IDeal>;
  pagination: IPagination;
}

export interface IPagination {
  page: number;
  hasNext: boolean;
  total: number;
}

export interface IDeal {
  contractId: string;
  deliveryCenterId: string;
  deliveryCenterName: string;
  vendorDealId: string;
  vendorPromotionId: string;
  accumulationType: string;
  dealPromotionId: string;
  priority: number;
  budget: number;
  availability: number;
  quantityLimit: number;
  type: string;
  score: number;
  isCrossDiscount: boolean;
  conditions: IConditions;
  output: IOutput;
  preview: IPreview;
  metadata: IMetadata;
  expireSoon: boolean;
  walletDealType: WalletDealTypeEnum;
  walletCreatedAt: string;
  enforcement: IEnforcement;
  encryptable: boolean;
}

export interface IEnforcement {
  quantityLimit: number;
  quantityLimitPerUser: number;
  users: Array<IEnforcementUser>;
}

export interface IMetadata {
  title: string;
  description: string;
  image: string;
  startDate: string;
  endDate: string;
  type: MetadataDiscountEnum;
}

export enum WalletDealTypeEnum {
  MANUAL = 'MANUAL',
  REGION = 'REGION',
}

export enum MetadataDiscountEnum {
  DISCOUNT = 'DISCOUNT',
  FREE_GOOD = 'FREE_GOOD',
  STEPPED_DISCOUNT = 'STEPPED_DISCOUNT',
  STEPPED_FREE_GOOD = 'STEPPED_FREE_GOOD',
  FLEXIBLE_DISCOUNT = 'FLEXIBLE_DISCOUNT',
}

export interface IPreview {
  type: string;
  conditions: Array<IConditions>;
  discounts: Array<IDiscount>;
}

export interface IDiscountContainer {
  price: number;
  browsePrice: number;
  discountAmount: number;
}

export interface IDiscount {
  from: number;
  to: number;
  itemId: string;
  vendorItemId: string;
  discountPercentage: number;
  freeGood: boolean;
  freeGoodMeasureUnit: string;
  quantity: number;
  fixed: boolean;
  proportion: number;
  maxQuantity: number;
  pack: IDiscountContainer;
  uom: IDiscountContainer;
  container: IDiscountContainer;
  itemDetail: IProduct;
}

export interface IConditions {
  paymentMethod: string;
  simulationDateTime: ISimulationDateTime;
  deliveryDate: Array<IDeliveryDate>;
  lineItem: ILineItem;
  multipleLineItem: IMultipleLineItem;
  couponCode: string;
  firstOrder: boolean;
  amounts: Array<IAmounts>;
}

export interface IAmounts {
  scope: string;
  operator: string;
  field: string;
  value: number;
}

export interface IDeliveryDate {
  startDate: string;
  endDate: string;
}

export interface ISimulationDateTime {
  startDateTime: string;
  endDateTime: string;
}

export interface IMultipleLineItem {
  items: Array<IMultipleLineItemItem>;
}

export interface IMultipleLineItemItem {
  vendorItemIds: Array<string>;
  minimumQuantity: number;
  itemsDetails: Array<IProduct>;
}

export interface ILineItem {
  vendorItemIds: Array<string>;
  minimumQuantity: number;
  sharedMinimumQuantity: boolean;
  itemsDetails: Array<IProduct>;
}

export interface IMultipleLineItemDiscount {
  type: string;
  items: Array<IMultipleLineItemDiscountItem>;
}

export interface IMultipleLineItemDiscountItem {
  vendorItemId: string;
  value: number;
  maxQuantity: number;
  itemDetail: IProduct;
}

export interface IOutput {
  orderTotalDiscount: IOrderTotalDiscount;
  lineItemDiscount: ILineItemDiscount;
  palletDiscount: IPalletDiscount;
  lineItemScaledDiscount: ILineItemScaledDiscount;
  freeGoods: IFreeGoods;
  scaledFreeGoods: IScaledFreeGoods;
  multipleLineItemDiscount: IMultipleLineItemDiscount;
}

export interface ILineItemDiscount {
  vendorItemIds: Array<string>;
  type: string;
  value: number;
  maxQuantity: number;
  proportion: number;
  fixed: boolean;
  itemsDetails: Array<IProduct>;
}

export interface IOrderTotalDiscount {
  type: string;
  discount: number;
  proportion: number;
  maxAmount: number;
  fixed: boolean;
}

export interface ILineItemScaledDiscount {
  vendorItemIds: Array<string>;
  ranges: Array<ILineItemScaledDiscountRange>;
  itemsDetails: Array<IProduct>;
}

export interface ILineItemScaledDiscountRange {
  from: number;
  to: number;
  proportion: number;
  type: string;
  value: number;
  maxQuantity: number;
  fixed: boolean;
}

export interface IFreeGoods {
  proportion: number;
  partial: boolean;
  items: Array<IFreeGoodsItem>;
}

export interface IFreeGoodsItem {
  vendorItems: Array<IVendorItem>;
  quantity: number;
}

export interface IVendorItem {
  vendorItemId: string;
  measureUnit: string;
  price: string;
  itemDetail: IProduct;
}

export interface IScaledFreeGoods {
  partial: boolean;
  ranges: Array<IScaledFreeGoodsRange>;
}

export interface IScaledFreeGoodsRange {
  from: number;
  to: number;
  proportion: number;
  items: Array<IFreeGoodsItem>;
  fixed: boolean;
}

export interface IPalletDiscount {
  discount: number;
  proportion: number;
  measureUnit: string;
}

export interface IProduct {
  id: string;
  brandId: string;
  brandName: string;
  casingDescription: string;
  classification: string;
  container: IItemContainer;
  createdDate: string;
  deleted: boolean;
  description: string;
  enabled: boolean;
  hidden: boolean;
  isAlcoholic: boolean;
  isNarcotic: boolean;
  palletQuantity: number;
  itemImage: string;
  itemName: string;
  minOrderQuantity: number;
  hasBeenUpdated: boolean;
  ozVolume: number;
  salesRanking: number;
  uncategorized: boolean;
  local: boolean;
  newRelease: boolean;
  upcCase: string;
  upcUnit: string;
  upc: string;
  variableWeight: boolean;
  package: IItemPackage;
  sku: string;
  subBrandName: string;
  updatedAt: string;
  vendorId: string;
  manufacturerId: string;
  styleType: string;
  distributorProductType: string;
  itemType: string;
  vendorItemId: string;
  abv: number;
  supplier: string;
  countryOfOrigin: string;
  ibu: number;
  itemPlatformId: string;
  dynamicAttributes: Array<IItemDynamicAtributes>;
  recommendedQuantity: number;
  available: boolean;
}

export interface IItemContainer {
  fullContainerDescription: string;
  itemSize: number;
  name: string;
  returnable: boolean;
  unitOfMeasurement: string;
  material: string;
}

export interface IItemPackage {
  fullPackageDescription: string;
  itemCount: number;
  materialType: string;
  name: string;
  pack: string;
  packageId: string;
  unitCount: number;
  size: number;
  unitOfMeasurement: string;
}

export interface IItemDynamicAtributes {
  fieldName: string;
  fieldValue: string;
  position: number;
}

export enum CouponTypeEnum {
  '$' = 'NOMINAL',
  '%' = 'PERCENTAGE',
}

export enum CouponTypeTranslatedEnum {
  NOMINAL = 'NOMINAL',
  PERCENTAGE = 'PERCENTAGE',
}

export enum CouponAmountFieldEnum {
  TOTAL = 'TOTAL',
  SUBTOTAL = 'SUBTOTAL',
}

export enum CouponOutputDiscountType {
  ORDER_LEVEL = 'ORDER_LEVEL',
  LINE_ITEM = 'LINE_ITEM',
}

export enum CouponTypeMutationEnum {
  PERCENT_OFF = 'PERCENT_OFF',
  PERCENT_OFF_SYMBOL = 'PERCENT_OFF_SYMBOL',
  AMOUNT_OFF = 'AMOUNT_OFF',
  AMOUNT_OFF_SYMBOL = 'AMOUNT_OFF_SYMBOL',
}

export enum CouponErrorTypesEnum {
  COUPON_ADMIN_ERROR = 'COUPON_ADMIN_ERROR',
  UNABLE_TO_ENCRYPT = 'UNABLE_TO_ENCRYPT',
  ENCRYPTION_CRITERIA_ERROR = 'ENCRYPTION_CRITERIA_ERROR',
}

export interface IUpdateCouponDTO {
  deliveryCenterIds: Array<string>;
  vendorPromotionId: string;
  title: string;
  couponCode: string;
  description: string;
  startDate: string;
  endDate: string;
  couponType: CouponTypeMutationEnum;
  couponValue: number;
  minimumOrder: number;
  maxDiscount: number;
  firstOrder: boolean;
  users: Array<string>;
  itemsIds: Array<string>;
  restrictionAmountField: CouponAmountFieldEnum;
  outputDiscountType: CouponOutputDiscountType;
  quantityLimit: number;
  quantityLimitPerUser: number;
  insertToUserWallet: boolean;
}

export interface ICreateCouponDTO {
  title: string;
  couponCode: string;
  description: string;
  startDate: string;
  endDate: string;
  couponType: CouponTypeMutationEnum;
  couponValue: number;
  minimumOrder?: number;
  maxDiscount?: number;
  quantityLimitPerUser: number;
  quantityLimit: number;
  firstOrder: boolean;
  insertToOriginalWallet?: boolean;
  insertToUserWallet?: boolean;
  users?: Array<string>;
  deliveryCentersIds?: Array<string>;
  itemsIds?: Array<string>;
  outputDiscountType: OutputDiscountType;
  usersFileName?: string;
  brazeWalleting?: boolean;
}
